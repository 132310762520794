import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Card,
  CardContent,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { useContext, useState } from 'react';

import ChangeAddressForm from 'components/ChangeAddressForm';
import KovoDialog from 'components/KovoDialog';
import PhoneNumberUpdate from 'components/PhoneNumberUpdate';
import { ROUTES } from 'components/Routes';
import { AuthContext } from 'context/AuthProvider';
import { theme } from 'context/ThemeProvider';
import useApplicationStatus from 'hooks/useApplicationStatus';
import { Link as RouterLink } from 'react-router-dom';

type UpdateType = 'email' | 'phone' | 'address';

type UpdateButtonProps = {
  children: React.ReactNode;
  onClick(): void;
};

/**
 * A styled button
 *
 * TODO: Why is fontSize needed here to unify the font sizing between @mui and
 * react-router-dom? Do we need to update the theme?
 */
const UpdateButton: React.FC<UpdateButtonProps> = ({ children, onClick }) => {
  return (
    <Link
      component="button"
      type="button"
      display="block"
      onClick={onClick}
      sx={{ marginTop: theme.spacing(0.25), fontSize: '1rem' }}
    >
      {children}
    </Link>
  );
};

const PersonalInfo: React.FC = () => {
  const [isOpen, setIsOpen] = useState<UpdateType | null>(null);
  const { track } = useProductAnalytics();

  const {
    data,
    isLoading,
    refetch: refetchApplicationStatus,
  } = useApplicationStatus();

  const { email } = useContext(AuthContext);

  if (isLoading) {
    return <Skeleton variant="rounded" height={372} />;
  }

  if (!data || !data.applicationDetails) {
    return null;
  }

  const fullName = `${data.applicationDetails.firstName} ${data.applicationDetails.lastName}`;
  const { address, address2, city, state, zipcode } = data.applicationDetails;

  const fullAddress = `${address}\n${
    address2 ? `${address2}\n` : ''
  }${city}, ${state} ${zipcode}`;

  const listItems = [
    { title: 'Name', value: fullName },
    { title: 'Email', value: email },
    { title: 'Mobile number', value: data?.applicationDetails?.phone },
    { title: 'Address', value: fullAddress },
  ];

  const modalContent = () => {
    switch (isOpen) {
      case 'phone':
        return (
          <>
            <DialogTitle variant="h4">Enter your new number</DialogTitle>
            <DialogContent>
              <Typography marginBottom={theme.spacing(3)}>
                We will need to verify your new mobile phone number.
              </Typography>

              <PhoneNumberUpdate onSuccess={handleClose} />
            </DialogContent>
          </>
        );
      case 'address':
        return <ChangeAddressForm onSuccess={handleClose} />;
    }
  };

  const handleClick = (updateType: UpdateType) => {
    setIsOpen(updateType);
  };

  const handleClose = () => {
    setIsOpen(null);
    refetchApplicationStatus();
  };

  return (
    <>
      <Card elevation={0}>
        <CardContent>
          <Typography variant="h5">PERSONAL INFORMATION</Typography>

          <Divider sx={{ margin: theme.spacing(2, 0) }} />

          <List disablePadding>
            {listItems.map(({ title, value }) => (
              <ListItem key={title} disableGutters disablePadding>
                <ListItemText
                  disableTypography
                  sx={{ margin: theme.spacing(1, 0), whiteSpace: 'pre-wrap' }}
                >
                  <Typography
                    variant="footnote"
                    marginBottom={theme.spacing(0.25)}
                  >
                    {title}
                  </Typography>
                  {/**
                   * Handle a long string value by breaking the word at the
                   * end of the line. This is especially important for email addresses.
                   */}
                  <Typography sx={{ wordWrap: 'break-word', maxWidth: '100%' }}>
                    {value}
                  </Typography>
                  {title === 'Email' && (
                    <Link
                      component={RouterLink}
                      to={ROUTES.SETTINGS_UPDATE_EMAIL}
                      sx={{
                        display: 'block',
                        marginTop: theme.spacing(0.25),
                        fontSize: '1rem',
                      }}
                      onClick={() =>
                        track({
                          namespace: 'ui',
                          event: 'settings.update-email.clicked',
                        })
                      }
                    >
                      Update email
                    </Link>
                  )}

                  {title === 'Mobile number' && (
                    <UpdateButton
                      onClick={() => {
                        track({
                          namespace: 'ui',
                          event: 'settings.update-phone-number.clicked',
                        });
                        handleClick('phone');
                      }}
                    >
                      Update mobile number
                    </UpdateButton>
                  )}

                  {title === 'Address' && (
                    <UpdateButton
                      onClick={() => {
                        track({
                          namespace: 'ui',
                          event: 'settings.update-address.clicked',
                        });
                        handleClick('address');
                      }}
                    >
                      Update address
                    </UpdateButton>
                  )}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>

      <KovoDialog
        onClose={handleClose}
        open={isOpen !== null}
        closeIcon={<ArrowBackIcon />}
      >
        {modalContent()}
      </KovoDialog>
    </>
  );
};

export default PersonalInfo;
