import { AxiosError } from 'axios';
import { ROUTES } from 'components/Routes/constants';
import { Logger } from './logger';

export const REACT_QUERY_UNKNOWN_ERROR_MESSAGE =
  'An unknown error was caught by react-query';

export const reactQueryOnError = (logger: Logger) => (error: unknown) => {
  logger.error(error, {
    prefix: 'React Query Error',
  });

  // Check if error is an AxiosError with status 503
  if (
    error &&
    error instanceof AxiosError &&
    error.response &&
    error.response.status === 503
  ) {
    window.location.href = ROUTES.ERROR_500_ROUTE;
  }
};
