import { Box, SxProps, Theme } from '@mui/material';
import { KovoAlert } from 'components/KovoAlert';
import { KovoAlertSeverity } from 'components/KovoAlert/KovoAlert';
import { theme } from 'context/ThemeProvider';
import { useEffect, useState } from 'react';

type KovoOutageAlertProps = {
  sx?: SxProps<Theme>;
  message: string;
};

const KovoOutageAlert = ({ sx, message }: KovoOutageAlertProps) => {
  const [outageAlertConfig, setOutageAlertConfig] = useState<
    | {
        severity: KovoAlertSeverity;
      }
    | undefined
  >();

  useEffect(() => {
    const payload = window.posthog?.getFeatureFlagPayload('KOVO_OUTAGE_ALERT');
    setOutageAlertConfig(
      payload as { severity: KovoAlertSeverity } | undefined,
    );
  }, []);

  if (!outageAlertConfig) {
    return null;
  }

  if (!outageAlertConfig.severity) {
    return null;
  }

  return (
    <Box sx={{ marginBottom: theme.spacing(4), ...sx }}>
      <KovoAlert severity={outageAlertConfig.severity} message={message} />
    </Box>
  );
};

export default KovoOutageAlert;
