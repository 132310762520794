import { LoadingButton } from '@mui/lab';
import { Alert, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { useRef } from 'react';
import * as yup from 'yup';

import {
  BIRTHDATE_INPUT_LABEL,
  BirthdateInput,
  birthdateInputSchema,
} from 'components/shared/BirthdateInput';
import Checkbox from 'components/shared/Checkbox';
import CurrencyInput from 'components/shared/CurrencyInput';
import SsnInput from 'components/shared/SsnInput';
import TextField from 'components/shared/TextField';
import { theme } from 'context/ThemeProvider';
import { ADDRESS_FORM_FIELDS } from 'helpers/addressFormUtils';
import { ZIP_CODE_REGEX } from 'helpers/constants';
import { FormField } from 'helpers/form';
import useApplicationSubmit from 'hooks/useApplicationSubmit';
import InvalidAddressDialog, { AddressData } from './InvalidAddressDialog';
import RecommendedAddressDialog from './RecommendedAddressDialog';

export interface FormValues {
  firstName: string;
  middleName: string;
  lastName: string;
  birthday: string;
  address: string;
  address2: string;
  state: string;
  city: string;
  zipcode: string;
  ssn: string;
  monthlyIncome: string;
  confirmDisclosure: boolean;
  isRecommendedAddress?: boolean;
}

const ApplyForm: React.FC = () => {
  const { track } = useProductAnalytics();
  const addressInputRef = useRef<HTMLInputElement>(null);

  const FORM_FIELDS: FormField[] = [
    {
      name: 'firstName',
      label: 'First name',
    },
    {
      name: 'middleName',
      label: 'Middle name (optional)',
    },
    {
      name: 'lastName',
      label: 'Last name',
    },
    {
      name: 'birthday',
      label: BIRTHDATE_INPUT_LABEL,
      Component: BirthdateInput,
    },
    ...ADDRESS_FORM_FIELDS,
    {
      name: 'ssn',
      label: 'SSN',
      Component: SsnInput,
    },
    {
      name: 'monthlyIncome',
      label: 'Monthly income ($)',
      Component: CurrencyInput,
    },
  ];

  const INITIAL_VALUES = {
    firstName: '',
    middleName: '',
    lastName: '',
    birthday: '',
    address: '',
    address2: '',
    state: '',
    city: '',
    zipcode: '',
    ssn: '',
    monthlyIncome: '',
    confirmDisclosure: false,
  };

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .min(2, 'First name is too short')
      .required('First name is required'),
    lastName: yup
      .string()
      .min(2, 'Last name is too short')
      .required('Last name is required'),
    birthday: birthdateInputSchema,
    address: yup.string().required('Street address is required'),
    state: yup.string().required('State is required'),
    city: yup.string().required('City is required'),
    zipcode: yup
      .string()
      .required('Zip code is required')
      .test('zipcode', 'Invalid zip code', (value = '') =>
        ZIP_CODE_REGEX.test(value),
      ),
    ssn: yup
      .string()
      .required('Social security number is required')
      .test('ssn', 'Invalid SSN', (value?: string) => value?.length === 11),
    monthlyIncome: yup.string().required('Income is required'),
    confirmDisclosure: yup.boolean().oneOf([true], 'Authorization is required'),
  });

  const onSubmit = (values: FormValues) => {
    mutate(values);
  };

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit,
    validateOnBlur: false,
  });

  const handleAddressSelect = ({
    address,
    address2,
    city,
    state,
    zipcode,
  }: Partial<AddressData>) => {
    setFieldValue('address', address || '');
    setFieldValue('address2', address2 || '');
    setFieldValue('city', city || '');
    setFieldValue('state', state || '');
    setFieldValue('zipcode', zipcode || '');
  };

  const {
    mutate,
    isLoading,
    error: applicationSubmitError,
    reset,
  } = useApplicationSubmit();

  const handleCloseDialog = () => {
    reset();
    addressInputRef.current?.focus();
  };

  const handleAddressConfirm = (addressValues: AddressData) => {
    handleAddressSelect(addressValues);
    mutate({
      ...values,
      ...addressValues,
      address2: addressValues.address2 || '',
      isRecommendedAddress: true,
    });
    window.scrollTo(0, document.body.scrollHeight);
  };

  return (
    <>
      {applicationSubmitError && (
        <>
          <InvalidAddressDialog
            open={applicationSubmitError.displayMessage === 'Invalid Address'}
            address={values.address}
            address2={values.address2}
            city={values.city}
            state={values.state}
            zipcode={values.zipcode}
            issues={applicationSubmitError.metadata.issues ?? []}
            missingComponents={
              applicationSubmitError.metadata.missingComponentTypes ?? []
            }
            onClose={handleCloseDialog}
          />

          <RecommendedAddressDialog
            open={
              applicationSubmitError.displayMessage === 'Questionable Address'
            }
            originalAddress={{
              address: values.address,
              address2: values.address2,
              city: values.city,
              state: values.state,
              zipcode: values.zipcode,
            }}
            formattedAddress={applicationSubmitError.metadata.formattedAddress}
            onConfirm={handleAddressConfirm}
            onClose={handleCloseDialog}
          />

          <Alert
            severity="error"
            icon={false}
            sx={{ marginBottom: theme.spacing(5) }}
          >
            {applicationSubmitError.displayMessage}

            {applicationSubmitError.metadata.errors?.map((error) =>
              Object.values(error).map((errorMessages) =>
                errorMessages.map((errorMessage) => (
                  <Typography variant="body2" key={errorMessage}>
                    {errorMessage}
                  </Typography>
                )),
              ),
            )}
          </Alert>
        </>
      )}

      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={{ xs: 2, sm: 3 }}
          marginBottom={theme.spacing(3)}
        >
          {FORM_FIELDS.map(
            ({ name, label, Component = TextField, helperText }, index) => {
              const props = {
                name,
                label,
                value: (values as any)[name],
                error: !!((touched as any)[name] && (errors as any)[name]),
                errorText: (errors as any)[name],
                helperText,
                setFieldValue,
                onAddressSelect: handleAddressSelect,
                onChange: handleChange,
                onBlur: handleBlur,
                fullWidth: true,

                ...(name === 'zipcode' && {
                  type: 'tel' as 'tel',
                  pattern: '[0-9-]*',
                  maxLength: 5,
                }),

                ...(name === 'address' && {
                  addressInputRef,
                }),

                ...(index === 0 && {
                  autoFocus: true,
                }),
              };

              return (
                <Grid item xs={12} key={name}>
                  <Component {...props} />
                </Grid>
              );
            },
          )}
        </Grid>

        <Checkbox
          label="Authorization for the Social Security Administration to Disclose Your Social Security Number Verification."
          name="confirmDisclosure"
          checked={values.confirmDisclosure}
          error={!!(touched.confirmDisclosure && errors.confirmDisclosure)}
          errorText={errors.confirmDisclosure}
          onChange={(e) => {
            track({
              namespace: 'ui',
              event: 'apply.ssn-disclosure.checked',
              attributes: {
                checked: e.target.checked,
              },
            });

            handleChange(e);
          }}
          sx={{ marginBottom: theme.spacing(4) }}
          showBorder
        />

        <Typography variant="footnote" marginBottom={theme.spacing(3)}>
          I authorize the Social Security Administration (SSA) to verify and
          disclose to Kovo Inc. through SentiLink Verification Services Corp.,
          their service provider for the purpose of this transaction whether the
          name, Social Security Number (SSN) and date of birth I have submitted
          matches information in SSA records. My consent is for a one-time
          validation within the next day.
        </Typography>

        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
          fullWidth
          sx={{ marginBottom: theme.spacing(3) }}
        >
          Submit
        </LoadingButton>
      </form>
    </>
  );
};

export default ApplyForm;
