import { Auth } from 'aws-amplify';
import {
  KovoError,
  KovoUnauthenticatedError,
  KovoUserNotConfirmedError,
} from 'libs/KovoError';

import { normalizeEmail } from 'helpers/utils';
import { signInUser } from './signInUser';
import { AuthErrorCode, AuthResult } from './types';

export const signupUser = async (
  unformattedEmail: string,
  password: string,
): Promise<AuthResult> => {
  const email = normalizeEmail(unformattedEmail);

  try {
    const result = await Auth.signUp({
      username: email,
      password,
      autoSignIn: {
        enabled: true,
      },
    });

    return {
      cognitoUser: result.user,
      cognitoUserConfirmed: result.userConfirmed,
    };
  } catch (error) {
    if (error instanceof Error) {
      switch (error.name) {
        case 'UsernameExistsException': {
          try {
            /**
             * Try to automatically sign in the user if the username is already taken
             */
            const signupResult = await signInUser(email, password);

            return signupResult;
          } catch (signinResultError) {
            if (signinResultError instanceof KovoUserNotConfirmedError) {
              return {
                cognitoUserConfirmed: false,
              };
            }

            // If this sign in error is something other than the UserNotConfirmed error,
            // swallow error here as we want to show the original sign up error to the user
            // instead of any automatic sign in error
          }

          /**
           * Throw a better error message than the one provided by Cognito. If we are able
           * to lock down Cognito in the future to not expose user accounts in errors, then
           * this may need to be a more generic message.
           */
          throw new KovoUnauthenticatedError(`${email} already exists`)
            .setError(error)
            .setCode(AuthErrorCode.USERNAME_EXISTS)
            .setLogLevel('warn')
            .addMetadata({ email })
            .exposeMessage();
        }
        case 'InvalidPasswordException': {
          throw new KovoUnauthenticatedError(error.message)
            .setError(error)
            .setCode(AuthErrorCode.INVALID_PASSWORD)
            .setLogLevel('warn')
            .addMetadata({ email })
            .exposeMessage();
        }
      }
    }

    throw new KovoError('Error signing up').setError(error);
  }
};
