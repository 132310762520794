import { ROUTES } from 'components/Routes';
import { useAuthContext } from 'context/AuthProvider';
import { normalizeEmail } from 'helpers/utils';
import { KovoError } from 'libs/KovoError';
import { getCognitoUserAuthDetails } from 'libs/signupHelpers/getCognitoUserAuthDetails';
import { signupUser } from 'libs/signupHelpers/signupUser';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import {
  identifyCustomer,
  useProductAnalytics,
} from 'libs/productAnalyticsTracking';

type SignupUserParams = {
  email: string;
  password: string;
};

export const useSignupUser = () => {
  const { authChangeCallback } = useAuthContext();
  const history = useHistory();
  const { track } = useProductAnalytics();
  const signupUserFn = async (email: string, password: string) => {
    const formattedEmail = normalizeEmail(email);
    const { cognitoUser, cognitoUserConfirmed } = await signupUser(
      formattedEmail,
      password,
    );

    /**
     * Somehow the user got to the signup page with a valid email and password
     * and has already completed email confirmation, so just send them to the
     * default route to be redirected to the next point in the onboarding up flow or
     * just to the home page if they are already fully through the onboarding flow.
     */
    if (cognitoUserConfirmed) {
      const userAuthDetails = await getCognitoUserAuthDetails(cognitoUser);
      await authChangeCallback(cognitoUser, userAuthDetails);

      history.replace(ROUTES.HOME);
      return;
    }

    const params = new URLSearchParams();
    params.set('email', email);

    /**
     * If the user is in the auto sign in flow from the sign up page, redirect but with less
     * data to try to sign them in automatically after they confirm their email
     */
    if (!cognitoUser) {
      await authChangeCallback(
        null,
        null,
        {
          email: formattedEmail,
          password,
        },
        true,
      );

      history.replace(`/signup-confirm?${params}`);
      return;
    }

    identifyCustomer({
      currentEmail: formattedEmail,
    });

    track({
      namespace: 'user',
      event: 'email.submission.succeeded',
      sendAsConversionEventToMobileApp: true,
    });

    /**
     * Normal flow where the user is in the process of confirming their email
     */
    const userAuthDetails = await getCognitoUserAuthDetails(cognitoUser);
    await authChangeCallback(cognitoUser, userAuthDetails, {
      email: formattedEmail,
      password,
    });

    history.replace('/signup-confirm');
  };

  const res = useMutation<void, KovoError, SignupUserParams>({
    mutationFn: (params: SignupUserParams) =>
      signupUserFn(params.email, params.password),
    mutationKey: 'signupUser',
  });

  return res;
};
