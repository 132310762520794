export interface User {
  userId: string;
  sk: string;
  status: UserStatus;
  signupEmail: string;
  firstName?: string;
  middleName?: string | null;
  fullName?: string;
  lastName?: string;
  currentEmail: string;
  birthday?: string;
  phone?: string;
  phoneNumber?: string | null;
  phoneNumberUnverified?: string | null;
  address?: string;
  address2?: string | null;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  timezone?: string;
  trustPilotUrl?: string;
  applicationSubmittedAt?: string;
  purchasedAt?: string;
  defaultPaymentProvider: 'checkout' | 'stripe';
  klaviyoId?: string;
  hellosignEmail: string | null;
  monthlyIncome?: number;
  contractProvider?: string;
  userPoolUserId: string;
  isEligibleForRewards3Months: boolean;
  closedAt?: string;
  createdAt: string;
  updatedAt: string;
  chargeAccountEnabled?: boolean;
  appDownloadPromptSeenAt?: string;
}

const coreUserStatuses = [
  'new',
  'completed',
  'rejected',
  'upfrontPaymentCompleted',
  'detailsNeeded',
  'paymentNeeded',
  'signatureNeeded',
  'kycNeeded',
  'kycPending',
  'kycPersonaFailed',
  'kycSentilinkFailed',
  'applicationRejected',
  'applicationAdverseAction',
] as const;

type CoreUserStatus = typeof coreUserStatuses[number];

const paidUserStatuses = ['paidOnSchedule', 'paidEarly'] as const;

type PaidUserStatuses = typeof paidUserStatuses[number];

export const closedUserStatuses = [
  'closed',
  'closedZeroBalance',
  'closedIncomplete',
  'closedAU',
  'paidEarly',
  'paidOnSchedule',
] as const;

export type ClosedUserStatus = typeof closedUserStatuses[number];

export const closedWithAccessUserStatuses = [
  'closedAU',
  'petitionChapter7',
  'closedChapter7',
  'petitionChapter13',
  'closedChapter13',
] as const;

export type ClosedWithAccessUserStatus =
  typeof closedWithAccessUserStatuses[number];

export type ClosedUserStatuses = ClosedUserStatus | ClosedWithAccessUserStatus;

export type UserStatus =
  | CoreUserStatus
  | PaidUserStatuses
  | ClosedUserStatus
  | ClosedWithAccessUserStatus;

/**
 * User statuses that indicate the user has yet to complete onboarding
 */
export const preOnboardingUserStatuses: UserStatus[] = [
  'new',
  'detailsNeeded',
  'paymentNeeded',
  'kycNeeded',
  'kycPending',
  'signatureNeeded',
];

export const userStatuses: UserStatus[] = [
  ...coreUserStatuses,
  ...paidUserStatuses,
  ...closedUserStatuses,
  ...closedWithAccessUserStatuses,
];

export const bankruptcyStatuses = [
  'petitionChapter7',
  'closedChapter7',
  'petitionChapter13',
  'closedChapter13',
] as const;

export type BankruptcyStatuses = typeof bankruptcyStatuses[number];

export const closedProductAccessRemainsStatuses: UserStatus[] = [
  'closedAU',
  'closedZeroBalance',
  'petitionChapter7',
  'petitionChapter13',
  'closedChapter7',
  'closedChapter13',
];

export type ForgotEmailResponse = {
  email: string;
};
