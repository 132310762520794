import { ROUTES } from 'components/Routes';
import { useAuthContext } from 'context/AuthProvider';
import { useGlobalRedirectContext } from 'context/GlobalRedirectContext';
import { KovoEmailVerificationError, KovoError } from 'libs/KovoError';
import { confirmEmailAttribute } from 'libs/signupHelpers/confirmEmailUpdate';
import { getCognitoUserAuthDetails } from 'libs/signupHelpers/getCognitoUserAuthDetails';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useClientsService } from '../useClientsService';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';

export interface ConfirmEmailAttributeParams {
  code: string;
  newEmail: string;
}

export const useConfirmEmailAttribute = () => {
  const { currentUser, authChangeCallback } = useAuthContext();
  const clientsService = useClientsService();
  const { setKey } = useGlobalRedirectContext();
  const { track } = useProductAnalytics();

  const history = useHistory();

  const confirmEmailAttributeFn = async ({
    code,
    newEmail,
  }: ConfirmEmailAttributeParams) => {
    try {
      if (!currentUser) {
        throw new KovoError(
          'Please log in before updating your email address.',
        ).exposeMessage();
      }

      const cognitoUser = await confirmEmailAttribute(code);
      const authDetails = await getCognitoUserAuthDetails(cognitoUser);
      await authChangeCallback(cognitoUser, authDetails);

      const queryParams = new URLSearchParams(history.location.search);
      const redirectPath = queryParams.get('redirectPath');

      /**
       * If the redirect path param exists and it looks like a path, not
       * another domain, redirect to that instead of home
       */
      if (redirectPath && redirectPath.startsWith('/')) {
        history.push(redirectPath);
      } else {
        history.push(ROUTES.HOME);
      }

      setKey('updateEmailConfirmation', false);

      return cognitoUser;
    } catch (error) {
      if (error instanceof KovoEmailVerificationError) {
        track({
          namespace: 'user-error',
          event: 'email.update.taken',
          attributes: {
            newEmail,
          },
        });
      }

      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Error updating email address', {
        error,
      }).exposeMessage();
    }
  };

  const res = useMutation<void, KovoError, ConfirmEmailAttributeParams>({
    mutationFn: confirmEmailAttributeFn,
    mutationKey: 'confirmEmailAttribute',
    onSuccess: async () => {
      /**
       * Make this request on a best effort basis until we are flowing Cognito events.
       * This WILL fail in the local environment until the local cognito container mock is
       * used.
       */
      try {
        await clientsService.post('/v1/update-current-email', {});
      } catch (error) {
        if (error instanceof KovoError) {
          throw error;
        }

        throw new KovoError('Error updating current email', { error });
      }
    },
  });

  return res;
};
