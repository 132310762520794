import { API } from 'aws-amplify';
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'components/Routes';
import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { USE_APPLICATIONS_QUERY_KEY } from 'hooks/queries/useGetApplications';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { Application, CHARGE_ACCOUNT_PRODUCT_ID } from 'types/schemas';

export type TemplateId = 'charge-account' | 'installments-risc-10';

function useSetEsignViewed(contractTemplateId: TemplateId) {
  const { identityId, username } = useContext(AuthContext);
  const { track } = useProductAnalytics();
  const queryClient = useQueryClient();
  const history = useHistory();

  const setEsignViewed = async (application?: Application) => {
    if (!application) {
      throw new Error('Application is undefined');
    }

    const res = await API.post(
      'clientsService',
      `/v1/applications/${application.sk}/esign-viewed`,
      {
        body: { contractTemplateId },
        headers: {
          ...amplifyRequestContext(identityId, username, {
            'x-api-key': import.meta.env.VITE_CLIENTS_SERVICE_API_KEY!,
            'x-user-id': identityId,
            'content-type': 'application/json',
          }).headers,
        },
      },
    );

    return res;
  };

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [USE_APPLICATIONS_QUERY_KEY],
    });
    history.replace(ROUTES.CREDIT_LINE_PAYMENT);
    if (contractTemplateId === 'charge-account') {
      track({
        namespace: 'application',
        event: 'esign.agreed',
        attributes: {
          // Hardcoding these values here since this `useSetEsignViewed` hook is only used for ChargeAccount
          accountType: 'charge_revolving',
          loanProductId: CHARGE_ACCOUNT_PRODUCT_ID,
          digitalServiceId: 'service_2',
        },
      });
    }
  };

  return useMutation(setEsignViewed, { onSuccess });
}

export default useSetEsignViewed;
