import { API } from 'aws-amplify';
import { AxiosError } from 'axios';
import { useContext } from 'react';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';

import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { USE_CURRENT_USER_QUERY_KEY } from 'hooks/queries/useCurrentUser';
import { USE_APPLICATION_STATUS_QUERY_KEY } from 'hooks/queryKeys';
import { KovoError } from 'libs/KovoError';
import { User } from 'types/schemas';

function useSavePhoneNumber(
  options?: UseMutationOptions<void, KovoError, any, any>,
) {
  const { identityId, username } = useContext(AuthContext);

  const queryClient = useQueryClient();

  const savePhoneNumber = async (phoneNumber: string) => {
    try {
      await API.post('installments', '/installments/phone-number', {
        body: {
          phoneNumber,
        },
        ...amplifyRequestContext(identityId, username),
      });
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      let displayMessage = 'Error sending verification code. Please try again.';

      const serverMessage =
        error instanceof AxiosError ? error.response?.data?.message : undefined;

      if (typeof serverMessage === 'string') {
        switch (serverMessage) {
          case 'Phone number matches existing phone number':
            displayMessage =
              "You've entered your existing phone number. To update, please enter a new number.";
            break;
          case 'Invalid phone number':
            displayMessage =
              "Sorry, that isn't a valid phone number. Please re-enter and try again.";
            break;
          case 'Max send attempts reached':
            displayMessage =
              'Sorry, there have been too many verification attempts for this number. Please wait 10 minutes and try again.';
            break;
          case 'Landline or unreachable carrier':
            displayMessage =
              "Sorry, we can't accept landlines. Please try again with a mobile number.";
            break;
        }
      }

      throw new KovoError('Error saving phone number')
        .setDisplayMessage(displayMessage)
        .setError(error);
    }
  };

  const onSuccess = async () => {
    // Refetch the current user and set its query data to the latest value
    // We could invalidate here, but we want to refetch/update cache immediately
    await queryClient.fetchQuery<User>(USE_CURRENT_USER_QUERY_KEY, {
      staleTime: 0,
      cacheTime: 0,
    });
    queryClient.invalidateQueries({
      queryKey: [USE_APPLICATION_STATUS_QUERY_KEY],
    });

    if (options?.onSuccess) {
      options.onSuccess(undefined, undefined, undefined);
    }
  };

  return useMutation<void, KovoError, string>(savePhoneNumber, {
    ...(options && { ...options }),
    onSuccess,
  });
}

export default useSavePhoneNumber;
