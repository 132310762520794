import { LoadingButton } from '@mui/lab';
import { Alert, Box, Link, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';

import TextField from 'components/shared/TextField';
import { theme } from 'context/ThemeProvider';
import { useConfirmEmailAttribute } from 'hooks/mutations/useConfirmEmailUpdate';
import { useVerifyEmailAttribute } from 'hooks/mutations/useVerifyEmailAttribute';

export interface Props {
  newEmail: string;
}

interface FormValues {
  code: string;
  newEmail: string;
}

const ChangeEmailConfirmationForm: React.FC<Props> = ({ newEmail }) => {
  const [resendComplete, setResendComplete] = useState(false);
  const { mutate, isLoading, error } = useConfirmEmailAttribute();
  const {
    mutate: resendCode,
    isLoading: resendCodeIsLoading,
    error: resendError,
  } = useVerifyEmailAttribute();

  const onSubmit = (values: FormValues) => {
    mutate(values);
  };

  const onResend = () => {
    resendCode();
    setResendComplete(true);
  };

  const { touched, errors, values, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        code: '',
        newEmail,
      },
      validationSchema: yup.object().shape({
        code: yup.string().required('Code is required'),
      }),
      onSubmit,
    });

  const renderResendLink = () => {
    if (resendError) {
      return (
        <Typography sx={{ textAlign: 'center' }} variant="footnote">
          Error sending verification code
        </Typography>
      );
    }

    if (resendCodeIsLoading) {
      return (
        <Typography sx={{ textAlign: 'center' }} variant="footnote">
          Sending...
        </Typography>
      );
    }

    if (resendComplete) {
      return (
        <Typography sx={{ textAlign: 'center' }} variant="footnote">
          Sent!
        </Typography>
      );
    }

    /**
     * TODO: why is this styling necessary, can this be done in the theme?
     */

    return (
      <Typography sx={{ textAlign: 'center' }} variant="footnote">
        <Link
          type="button"
          component="button"
          sx={{
            fontSize: 'inherit',
            display: 'inline',
            lineHeight: 'inherit',
            verticalAlign: 'inherit',
          }}
          onClick={() => onResend()}
        >
          Resend verification code
        </Link>
      </Typography>
    );
  };

  const errorMessage = error
    ? error.displayMessage || error.message
    : undefined;

  const resendErrorMessage = resendError
    ? resendError.displayMessage || resendError.message
    : undefined;

  return (
    <>
      <form
        onSubmit={handleSubmit}
        data-testid="change-email-confirmation-form"
      >
        {error ? (
          <Alert
            severity="error"
            icon={false}
            sx={{ marginBottom: theme.spacing(2) }}
          >
            {errorMessage}
          </Alert>
        ) : null}

        {resendError ? (
          <Alert
            severity="error"
            icon={false}
            sx={{ marginBottom: theme.spacing(2) }}
          >
            {resendErrorMessage}
          </Alert>
        ) : null}

        <Box sx={{ marginTop: theme.spacing(2) }}>
          <Typography variant="footnote">New email</Typography>
          <Typography sx={{ wordWrap: 'break-word', maxWidth: '100%' }}>
            <strong>{newEmail}</strong>
          </Typography>
        </Box>

        <Box
          sx={{ marginBottom: theme.spacing(4), marginTop: theme.spacing(2) }}
        >
          <TextField
            name="code"
            label="Email confirmation code"
            error={!!(touched.code && errors.code)}
            errorText={errors.code}
            value={values.code}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </Box>

        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
          fullWidth
        >
          Verify
        </LoadingButton>
      </form>

      <Typography variant="footnote" sx={{ marginTop: theme.spacing(4) }}>
        {renderResendLink()}
      </Typography>
    </>
  );
};

export default ChangeEmailConfirmationForm;
