import { Button, Link as MuiLink, Typography } from '@mui/material';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';

import KovoOutageAlert from 'components/KovoOutageAlert';
import LogInForm from 'components/LogInForm';
import OnboardingPage from 'components/OnboardingPage';
import { AuthContext } from 'context/AuthProvider';
import { theme } from 'context/ThemeProvider';

const LogInPage: React.FC = () => {
  const { currentUser } = useContext(AuthContext);
  const { track } = useProductAnalytics();

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Helmet>
        <title>Log In to your Kovo Account</title>
        <meta
          name="description"
          content="Log in to access Installments, Courses, and Rewards. Auto-pay makes it easy to stay on track with your monthly payments."
        />
      </Helmet>

      <OnboardingPage centerLogo linkLogoToHomepage supportSubject="login">
        <KovoOutageAlert message="Login is temporarily unavailable. We’re working on it. Please come back later." />

        <Typography
          variant="h1"
          sx={{
            marginBottom: theme.spacing(4),
          }}
        >
          Log in
        </Typography>

        <LogInForm />

        <Typography textAlign="center" marginTop={theme.spacing(3)}>
          <MuiLink
            onClick={() => {
              track({
                namespace: 'ui',
                event: 'forgot-password.clicked',
              });
            }}
            component={Link}
            to="/forgot-password"
          >
            Forgot your password?
          </MuiLink>
        </Typography>

        <Typography
          textAlign="center"
          marginTop={theme.spacing(2)}
          marginBottom={theme.spacing(6)}
        >
          <MuiLink
            onClick={() => {
              track({
                namespace: 'ui',
                event: 'forgot-email.clicked',
              });
            }}
            component={Link}
            to="/forgot-email"
          >
            Forgot your email?
          </MuiLink>
        </Typography>

        <Typography
          variant="h3"
          textAlign="center"
          marginBottom={theme.spacing(3)}
        >
          Don’t have an account?
        </Typography>

        <Button
          variant="outlined-gradient"
          component={Link}
          to="/signup"
          fullWidth
          onClick={() => {
            track({
              namespace: 'ui',
              event: 'signup.clicked',
            });
          }}
        >
          Sign Up
        </Button>
      </OnboardingPage>
    </>
  );
};

export default LogInPage;
