import { API } from 'aws-amplify';
import { useContext } from 'react';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';

import { AxiosError } from 'axios';
import { AuthContext } from 'context/AuthProvider';
import { amplifyRequestContext } from 'helpers/amplify-request-context';
import { USE_CURRENT_USER_QUERY_KEY } from 'hooks/queries/useCurrentUser';
import { USE_APPLICATION_STATUS_QUERY_KEY } from 'hooks/queryKeys';
import { KovoError } from 'libs/KovoError';
import { User } from 'types/schemas';

function useVerifyPhoneNumber(
  options?: UseMutationOptions<void, Error, any, any>,
) {
  const { identityId, username } = useContext(AuthContext);

  const queryClient = useQueryClient();

  const verifyPhoneNumber = async (verificationCode: string) => {
    try {
      await API.post('installments', '/installments/verify-phone-number', {
        body: {
          verificationCode,
        },
        ...amplifyRequestContext(identityId, username),
      });
    } catch (error) {
      let displayMessage = 'Error verifying phone number. Please try again.';

      const serverMessage =
        error instanceof AxiosError ? error.response?.data?.message : undefined;

      if (typeof serverMessage === 'string') {
        switch (serverMessage) {
          case 'Invalid parameter: Code':
          case 'Verfication failed':
            displayMessage = 'Invalid verification code. Please try again.';
            break;
          case 'Max check attempts reached':
            displayMessage =
              'Sorry, there have been too many verification attempts for this number. Please wait 10 minutes and try again.';
            break;
        }
      }

      throw new KovoError('Error verifying phone number')
        .setDisplayMessage(displayMessage)
        .setError(error);
    }
  };

  const onSuccess = async () => {
    queryClient.invalidateQueries({
      queryKey: [USE_APPLICATION_STATUS_QUERY_KEY],
    });
    // Refetch the current user and set its query data to the latest value
    // We could invalidate here, but we want to refetch/update cache immediately
    await queryClient.fetchQuery<User>(USE_CURRENT_USER_QUERY_KEY, {
      staleTime: 0,
      cacheTime: 0,
    });

    if (options?.onSuccess) {
      options.onSuccess(undefined, undefined, undefined);
    }
  };

  return useMutation<void, KovoError, string, unknown>(verifyPhoneNumber, {
    ...(options && { ...options }),
    onSuccess,
  });
}

export default useVerifyPhoneNumber;
