import { Box, Link as MuiLink, Typography } from '@mui/material';

import LogOutButton from 'components/LogOutButton';
import { AdaContext } from 'context/AdaProvider';
import { AuthContext } from 'context/AuthProvider';
import { theme } from 'context/ThemeProvider';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { useContext } from 'react';

interface Props {
  supportSubject?: string;
  openSupportAsLink?: boolean;
}

const Footer: React.FC<Props> = ({ supportSubject, openSupportAsLink }) => {
  const { startBot } = useContext(AdaContext);
  const { track } = useProductAnalytics();
  const { currentUser } = useContext(AuthContext);

  return (
    <Box
      component="footer"
      padding={theme.spacing(4, 0, 0)}
      textAlign="center"
      data-testid="footer"
    >
      <Typography variant="footnote" marginBottom={theme.spacing(1)}>
        Need help?{' '}
        <MuiLink
          variant="footnote"
          component="button"
          onClick={() => {
            track({
              namespace: 'ui',
              event: 'footer.get-in-touch.clicked',
            });

            startBot({
              subject: supportSubject,
              openAsLink: openSupportAsLink,
            });
          }}
          style={{ verticalAlign: 'baseline' }}
        >
          Get in touch
        </MuiLink>
        .
      </Typography>

      {currentUser && <LogOutButton />}
    </Box>
  );
};

export default Footer;
