export type Platform = 'web' | 'iosapp' | 'androidapp';

/**
 * This function will get the signup platform for a user.
 */
const getCurrentPlatform = (): Platform => {
  if (window.KovoAppCustomInfo && window.KovoAppCustomInfo.platform) {
    return `${window.KovoAppCustomInfo.platform}app`;
  }

  return 'web';
};

export default getCurrentPlatform;
