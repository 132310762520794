import { ROUTES } from 'components/Routes';
import { useAuthContext } from 'context/AuthProvider';
import { useGlobalRedirectContext } from 'context/GlobalRedirectContext';
import { KovoError } from 'libs/KovoError';
import { cancelEmailAttribute } from 'libs/signupHelpers';
import { getCognitoUserAuthDetails } from 'libs/signupHelpers/getCognitoUserAuthDetails';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';

export const useCancelEmailUpdate = () => {
  const { currentUser, authChangeCallback } = useAuthContext();
  const { setKey } = useGlobalRedirectContext();

  const history = useHistory();

  const cancelEmailUpdateFn = async () => {
    try {
      if (!currentUser) {
        history.replace(ROUTES.LOGIN_ROUTE);
        return;
      }

      const cognitoUser = await cancelEmailAttribute();
      const authDetails = await getCognitoUserAuthDetails(cognitoUser);
      await authChangeCallback(cognitoUser, authDetails);

      history.replace(ROUTES.HOME);

      /**
       * Remove the key from the global redirect context so that the user
       * is not redirected to the confirmation page.
       */
      setKey('updateEmailConfirmation', false);

      return cognitoUser;
    } catch (error) {
      /**
       * Just log the error since removing the custom attributes is not critical.
       */
      rollbarEnabled &&
        rollbar.warn('Error cancelling email update', { error });

      history.replace(ROUTES.HOME);
    }
  };

  const res = useMutation<void, KovoError, void>({
    mutationFn: cancelEmailUpdateFn,
    mutationKey: 'cancelEmailUpdate',
  });

  return res;
};
