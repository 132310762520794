import { ROUTES } from 'components/Routes';
import { useAuthContext } from 'context/AuthProvider';
import { normalizeEmail } from 'helpers/utils';
import {
  KovoEmailVerificationError,
  KovoError,
  KovoUnauthenticatedError,
  KovoUserCreationError,
} from 'libs/KovoError';
import { confirmUserEmail } from 'libs/signupHelpers/confirmUserEmail';
import { signInUser } from 'libs/signupHelpers/signInUser';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useClientsService } from './useClientsService';
import { useHandlePostUserSignup } from './useHandlePostUserSignup';
import { AuthResult } from 'libs/signupHelpers/types';

export interface ConfirmSignupParams {
  code: string;
}

const useConfirmUserSignup = () => {
  const { preConfirmationState } = useAuthContext();

  const { mutateAsync: handlePostUserSignup } = useHandlePostUserSignup();
  const clientsService = useClientsService();
  const history = useHistory();

  const confirmSignUp = async ({ code }: ConfirmSignupParams) => {
    try {
      if (!preConfirmationState) {
        throw new KovoError('User not in pre-confirmation state');
      }

      const email = normalizeEmail(preConfirmationState.email);

      await confirmUserEmail(email, code);

      let signInResult: AuthResult;

      try {
        signInResult = await signInUser(email, preConfirmationState.password);
      } catch (error) {
        /**
         * Email confirmation was successful but the user was unable to sign in.
         * This means that the password is incorrect and the user needs to be routed
         * to the log in page to sign in again. We also show a banner to encourage the
         * user to log in again, or they can go through the forgot password flow if
         * needed.
         */
        if (error instanceof KovoUnauthenticatedError) {
          history.replace({
            pathname: ROUTES.LOGIN_ROUTE,
            state: {
              emailConfirmed: true,
              loginEmail: email,
            },
          });

          return;
        }

        throw error;
      }

      if (!signInResult.cognitoUserConfirmed) {
        throw new KovoError('Error confirming user signup');
      }

      await handlePostUserSignup({
        cognitoUser: signInResult.cognitoUser,
        clientsService,
      });
    } catch (error) {
      if (error instanceof KovoError) {
        throw error;
      }

      throw new KovoError('Error confirming user signup', { error });
    }
  };

  const res = useMutation<
    void,
    | KovoError
    | KovoUnauthenticatedError
    | KovoEmailVerificationError
    | KovoUserCreationError,
    ConfirmSignupParams
  >({
    mutationFn: confirmSignUp,
    mutationKey: 'confirmSignUp',
    onError(error) {
      if (error instanceof KovoUserCreationError) {
        history.push(ROUTES.SIGNUP_PENDING_ROUTE);
      }
    },
  });

  return res;
};

export default useConfirmUserSignup;
