import {
  Box,
  Button,
  Card,
  CardContent,
  Link as MuiLink,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { iconMapping } from 'components/OfferCategoriesList/OfferCategoriesList';
import { theme } from 'context/ThemeProvider';
import { Reward } from '../RewardsList';

interface Props {
  reward: Reward;
}

function singularize(word: string) {
  const endingsMapping: { [key: string]: string } = {
    ves: 'fe',
    ies: 'y',
    i: 'us',
    zes: 'ze',
    ses: 's',
    es: 'e',
    s: '',
  };

  return word.replace(
    new RegExp(`(${Object.keys(endingsMapping).join('|')})$`),
    (match) => endingsMapping[match],
  );
}

const RewardCard: React.FC<Props> = ({ reward }) => {
  const { name, status, categoryId, categoryName } = reward;

  return (
    <Card elevation={0} data-testid="reward-card">
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
          }}
        >
          <Box component="img" src={iconMapping[categoryName]} alt="" />
          <Typography variant="h3">{categoryName}</Typography>
        </Box>

        <Typography data-testid="reward-name" marginBottom={theme.spacing(1.5)}>
          {name}
        </Typography>

        {status === 'locked' && (
          <Typography variant="footnote">
            You are not yet eligible for this reward. In the meantime, browse{' '}
            {singularize(categoryName.toLowerCase())} offers{' '}
            <MuiLink
              component={Link}
              to={`/offer-categories/${categoryId}/offers`}
            >
              here
            </MuiLink>
            .
          </Typography>
        )}

        {status === 'unlocked' && (
          <Button
            variant="outlined-gradient"
            fullWidth
            component={Link}
            to={`/offer-categories/${categoryId}/offers`}
          >
            View Offers
          </Button>
        )}

        {(status === 'claimable' || status === 'claimed') && (
          <Typography variant="footnote">
            Access your earnings in your{' '}
            <MuiLink component={Link} to="/wallet">
              wallet
            </MuiLink>
            .
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default RewardCard;
