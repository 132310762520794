import { Divider, Grid, Skeleton, Typography } from '@mui/material';

import { theme } from 'context/ThemeProvider';
import { useRewardsQuery } from 'hooks/queries/useRewardsQuery';
import RewardCard from './RewardCard';

export interface Reward {
  id: string;
  name: string;
  description: string;
  categoryId: string;
  categoryName: string;
  rewardAmountDollars: null | number;
  status: 'locked' | 'unlocked' | 'claimable' | 'claimed';
  giftCardLink: null | string;
}

const displayOrder = [
  'Credit Cards',
  'Personal Loans',
  'Auto Loan Refinancing',
  'Student Loans',
  'Student Loan Refinancing',
  'Services',
  'Insurance',
];

const RewardsList: React.FC = () => {
  const { data: rewards, isLoading } = useRewardsQuery();

  if (isLoading) {
    return (
      <>
        <Skeleton
          variant="rounded"
          height={150}
          sx={{ marginBottom: theme.spacing(2) }}
        />
        <Skeleton variant="rounded" height={150} />
      </>
    );
  }

  // <OfferEligibleBanner /> displays a banner for this case
  if (rewards?.length === 0) {
    return null;
  }

  const sortedRewards = displayOrder.flatMap((categoryName) => {
    return (
      rewards?.filter((reward) => reward.categoryName === categoryName) ?? []
    );
  });

  const lockedRewards = sortedRewards?.filter(
    (reward) => reward.status === 'locked',
  );

  const availableRewards = sortedRewards?.filter(
    (reward) => reward.status === 'unlocked',
  );

  const usedRewards = sortedRewards?.filter(
    (reward) => reward.status === 'claimable' || reward.status === 'claimed',
  );

  return (
    <>
      <Grid data-testid="locked-rewards" container spacing={2}>
        {lockedRewards?.map((reward) => (
          <Grid item xs={12} key={reward.id}>
            <RewardCard reward={reward} />
          </Grid>
        ))}
      </Grid>

      {!!availableRewards?.length && (
        <>
          <Typography variant="h5" marginTop={theme.spacing(3)}>
            AVAILABLE
          </Typography>

          <Divider sx={{ margin: theme.spacing(2, 0) }} />

          <Grid data-testid="available-rewards" container spacing={2}>
            {availableRewards.map((reward) => (
              <Grid item xs={12} key={reward.id}>
                <RewardCard reward={reward} />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {!!usedRewards?.length && (
        <>
          <Typography variant="h5" marginTop={theme.spacing(3)}>
            USED
          </Typography>

          <Divider sx={{ margin: theme.spacing(2, 0) }} />

          <Grid data-testid="used-rewards" container spacing={2}>
            {usedRewards.map((reward) => (
              <Grid item xs={12} key={reward.id}>
                <RewardCard reward={reward} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default RewardsList;
