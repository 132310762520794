import { Box, Container } from '@mui/material';
import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from 'react-router-dom';

import Footer from 'components/Footer';
import Header from 'components/Header';
import config from 'config';
import { GREY, WHITE, theme } from 'context/ThemeProvider';
import usePrevious from 'hooks/usePrevious';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';

interface Props {
  hideMenuButton?: boolean;
  showReferralButton?: boolean;
  maxWidth?: string;
  children?: React.ReactNode;
  overridePadding?: {
    xs?: number | string;
    sm?: number | string;
    md?: number | string;
  };
}

const Page: React.FC<Props> = ({
  hideMenuButton,
  showReferralButton,
  maxWidth,
  overridePadding,
  children,
}) => {
  const location = useLocation();
  const pathname = location.pathname;
  const previousPathname = usePrevious(pathname);
  const { track } = useProductAnalytics();

  useEffect(() => {
    if (config.VITE_STAGE === 'prod') {
      ReactPixel.pageView();
    }
  }, [location]);

  useEffect(() => {
    if (previousPathname !== pathname) {
      track({
        namespace: 'ui',
        event: 'page.viewed',
      });
    }
  }, [previousPathname, pathname]);

  return (
    <Box
      sx={{
        background: `linear-gradient(180deg, ${WHITE} 100px, ${GREY} 200px)`,
        minHeight: '100vh',
      }}
    >
      <Header
        hideMenuButton={hideMenuButton}
        showReferralButton={showReferralButton}
      />

      <Container
        sx={{
          padding: overridePadding?.xs
            ? overridePadding.xs
            : theme.spacing(1, 1.5),
          position: 'relative',

          minHeight: 'calc(100vh - 58px)',

          display: 'flex',
          flexDirection: 'column',

          [theme.breakpoints.up('sm')]: {
            padding: overridePadding?.sm
              ? overridePadding.sm
              : theme.spacing(2, 3),
            maxWidth: maxWidth || '390px',

            minHeight: 'calc(100vh - 72px)',
          },

          [theme.breakpoints.up('md')]: {
            maxWidth: maxWidth || '408px',
            padding: overridePadding?.md
              ? overridePadding.md
              : theme.spacing(3),
          },
        }}
        fixed
      >
        <Box sx={{ flexGrow: 1 }}>{children}</Box>

        <Footer />
      </Container>
    </Box>
  );
};

export default Page;
