import { onConfirmPaymentMethod } from 'components/CheckoutPaymentMethods';
import useCurrentUser, {
  USE_CURRENT_USER_QUERY_KEY,
} from 'hooks/queries/useCurrentUser';
import { USE_ACCOUNTS_QUERY_KEY } from 'hooks/queries/useGetAccounts';
import { USE_APPLICATION_STATUS_QUERY_KEY } from 'hooks/queryKeys';
import useApplicationStatus from 'hooks/useApplicationStatus';
import useBillingService from 'hooks/useBillingService';
import { useTrackConversion } from 'hooks/useTrackConversion';
import { KovoError } from 'libs/KovoError';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { INSTALLMENTS_PRODUCT_ID } from 'types/schemas';

export const useProcessInstallmentPayment = () => {
  const { data: user } = useCurrentUser();
  const billingService = useBillingService();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { data: applicationStatusData } = useApplicationStatus();
  const trackConversion = useTrackConversion();

  const handleConfirmPaymentMethod = useCallback<onConfirmPaymentMethod>(
    async ({ token, walletType, last4 }) => {
      try {
        if (!applicationStatusData) {
          throw new KovoError('Application status data is missing');
        }

        if (!user) {
          throw new KovoError('Current user is missing');
        }

        const userId = user.userId;
        const email = user.currentEmail;
        const username = user.userPoolUserId;

        const url = new URL(window.location.href);
        const customProduct = url.searchParams.get('product');

        await billingService.post('/billing/checkout/confirm-payment', {
          email,
          token,
          product: customProduct,
          type: walletType,
          last4,
        });

        trackConversion(userId, username, email, INSTALLMENTS_PRODUCT_ID);

        queryClient.invalidateQueries({
          queryKey: [USE_ACCOUNTS_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [USE_CURRENT_USER_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [USE_APPLICATION_STATUS_QUERY_KEY],
        });

        history.replace('/');
      } catch (error) {
        if (error instanceof KovoError) {
          throw error;
        }

        throw new KovoError('Error completing payment')
          .addMetadata({
            walletType,
            loanProductId: 'pro_1',
          })
          .setError(error);
      }
    },
    [billingService, history, queryClient, applicationStatusData, user],
  );

  return useMutation({
    mutationFn: handleConfirmPaymentMethod,
  });
};
