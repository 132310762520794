export const showSingularBannerForNonWebviewPhones = () => {
  // If in web view, set session storage var to true
  // If NOT in mobile webview and session storage is not true, send event to show banner
  const MOBILE_WEBVIEW_URL_PARAM = 'mobile_webview';
  const IS_MOBILE_WEBVIEW_KEY = 'is_mobile_webview';
  const isMobileWebViewURLParam =
    new URLSearchParams(window.location.search).get(
      MOBILE_WEBVIEW_URL_PARAM,
    ) === 'true';
  if (isMobileWebViewURLParam) {
    sessionStorage.setItem(IS_MOBILE_WEBVIEW_KEY, 'true');
  } else if (
    !isMobileWebViewURLParam &&
    sessionStorage.getItem(IS_MOBILE_WEBVIEW_KEY) !== 'true'
  ) {
    window.gtag('event', 'show_singular_banner');
  }
};
