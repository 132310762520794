import {
  getMobileAppDeviceInfo,
  sendMessageToMobileApp,
} from 'helpers/mobile-app';
import { rollbar, rollbarEnabled } from 'helpers/rollbar';
import { convertPhoneNumberToE164, hashString } from 'helpers/utils';
import { USE_CURRENT_USER_QUERY_KEY } from 'hooks/queries/useCurrentUser';
import { DateTime } from 'luxon';
import { Properties } from 'posthog-js';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import {
  CHARGE_ACCOUNT_PRODUCT_ID,
  DigitalServiceId,
  INSTALLMENTS_PRODUCT_ID,
  ProductTypes,
  User,
} from 'types/schemas';
import filterUndefinedValues from './filterUndefinedValues';
import getCurrentPlatform from './signupHelpers/getCurrentPlatform';

const USER_ERROR_ANALYTICS_EVENTS = ['email.update.taken'];
type UserErrorAnalyticsEvent = typeof USER_ERROR_ANALYTICS_EVENTS[number];

const USER_ANALYTICS_EVENTS = [
  'email.submission.succeeded',
  'email.verification.succeeded',
  'phone.submission.succeeded',
  'phone.verification.succeeded',
  'login.submission.succeeded',
] as const;
type UserAnalyticsEvent = typeof USER_ANALYTICS_EVENTS[number];

type StandardTrackingEventAttributes = {
  loanProductId?:
    | typeof INSTALLMENTS_PRODUCT_ID
    | typeof CHARGE_ACCOUNT_PRODUCT_ID; // The product ID on the account or application
  digitalServiceId: DigitalServiceId; // The digital service ID on the account or application
  accountType?: ProductTypes; // The account type on the account or application
};

const APPLICATION_ANALYTICS_EVENTS = [
  'esign.agreed',
  'submission.succeeded',
  'upfrontpayment.succeeded',
] as const;
type ApplicationAnalyticsEvent = typeof APPLICATION_ANALYTICS_EVENTS[number];
type ApplicationAttributes = StandardTrackingEventAttributes;

const PURCHASE_ANALYTICS_EVENTS = ['submission.succeeded'] as const;
type PurchaseAnalyticsEvent = typeof PURCHASE_ANALYTICS_EVENTS[number];
// service_1: course bundle 1 and ID monitoring
// service_2: course bundle 2 and IDT resolution/insurance
type PurchaseAttributes = StandardTrackingEventAttributes;

const UI_ANALYTICS_EVENTS = [
  'page.viewed',
  'forgot-password.clicked',
  'forgot-email.clicked',
  'signup.clicked',
  'signup.privacy-policy.clicked',
  'signup.terms-of-use.clicked',
  'signup.e-sign-consent.clicked',
  'signup.terms-and-conditions.checked',
  'resend-code.clicked',
  'signup.verify-phone-number-go-back.clicked',
  'apply.ssn-disclosure.checked',
  'apply.upfront-payment.clicked',
  'address-input.invalid-address.shown',
  'address-input.recommended-address.shown',
  'esign.reviewed',
  'product-benefits-table.view-more.clicked',
  'footer.get-in-touch.clicked',
  'referral.welcome-modal.seen',
  'referral.share-link.clicked',
  'referral.maybe-later.clicked',
  'app-download.welcome-modal.seen',
  'app-download.maybe-later.clicked',
  'header.referral-button.clicked',
  'settings.update-email.clicked',
  'settings.update-phone-number.clicked',
  'settings.update-address.clicked',
  'settings.update-password.clicked',
  'offers.offer-card.visit-site.clicked',
  'offers.offer-card.disclosure.clicked',
  'offers.offer-categories-list.category.clicked',
  'login.clicked',
  'logout.clicked',
  'payment.update-payment-method.clicked',
  'payment.view-invoice.clicked',
] as const;
type UIAnalyticsEvent = typeof UI_ANALYTICS_EVENTS[number];

type CommonAnalyticsParams = {
  properties?:
    | (Properties & {
        /**
         * In most cases, the event ID should not be hard coded.
         * Instead, it should be generated by the `generateEventId` function.
         * If the event ID is set, it should not include user identifying information
         * such as the user ID, email, phone number, etc.
         *
         * The one exception is the purchase event due to Google Ads conversion retractions.
         * The event ID is hard coded as the schema is important for services when processing
         * Google Ads conversion retractions. With the upcoming application migration project,
         * this will be changed and the event ID will be based on the purchase or account ID.
         */
        eventId?: string;
      })
    | null;
  /**
   * If true, the event will be sent as a conversion event to the mobile app.
   * The event will then be sent to Singular.
   * There is an environment check on the mobile app to ensure the event is only
   * sent in production.
   *
   * @default false
   * @type {boolean}
   */
  sendAsConversionEventToMobileApp?: boolean;
  attributes?: Object;
  user?: User;
};

export type TrackProductAnalyticsParams =
  | (CommonAnalyticsParams & {
      namespace: 'user';
      event: UserAnalyticsEvent;
    })
  | (CommonAnalyticsParams & {
      namespace: 'application';
      event: ApplicationAnalyticsEvent;
      attributes: ApplicationAttributes;
    })
  | (CommonAnalyticsParams & {
      namespace: 'purchase';
      event: PurchaseAnalyticsEvent;
      attributes: PurchaseAttributes;
    })
  | (CommonAnalyticsParams & {
      namespace: 'ui';
      event: UIAnalyticsEvent;
    })
  | (CommonAnalyticsParams & {
      namespace: 'user-error';
      event: UserErrorAnalyticsEvent;
    });

/**
 * @internal
 * Do not use this function directly. Instead, use the `useProductAnalytics` hook.
 * This ensures that the current user context is properly included in analytics events.
 *
 * @example
 * ```tsx
 * const { track } = useProductAnalytics();
 * track({ namespace: 'ui', event: 'page.viewed' });
 * ```
 */
export const trackProductAnalytics = ({
  namespace,
  event,
  attributes,
  properties,
  user,
  sendAsConversionEventToMobileApp,
}: TrackProductAnalyticsParams) => {
  const platform = getCurrentPlatform();
  const deviceInfo = getMobileAppDeviceInfo();
  const eventId = properties?.eventId ?? generateEventId();

  if (attributes) {
    // Merge attributes into properties
    properties = {
      ...properties,
      ...attributes,
    };
  }

  properties = {
    ...properties,
    eventId: eventId,
    value: properties?.value ?? 0,
    currency: properties?.currency ?? 'USD',
  };

  // Log the event to the console for debugging (only in development)
  if (import.meta.env.DEV) {
    console.log(`[GTM TRACKING]\nweb.${namespace}.${event}`, {
      ...properties,
      platform,
      deviceInfo,
      currentPath: window.location.pathname,
      user,
    });
  }

  const eventName = `web.${namespace}.${event}`;
  // We started sending Posthog events through GTM in version 107 of the GTM container
  // For safety, we'll still send to Posthog if the GTM version is not set or is invalid
  const gtmContainerVersion = window.gtm_container_version
    ? Number(window.gtm_container_version)
    : null;
  const shouldSendToPosthog =
    !gtmContainerVersion ||
    isNaN(gtmContainerVersion) ||
    gtmContainerVersion < 107;

  if (shouldSendToPosthog) {
    // Send to Posthog
    window.posthog?.capture(eventName, {
      ...properties,
      platform,
      deviceInfo,
      currentPath: window.location.pathname,
    });
  }

  const gtmEventParameters = {
    ...convertPropertyNamesToSnakeCase(properties ?? {}),
  };

  if (user) {
    gtmEventParameters.user_id = user.userId;
  }

  // Send to GTM
  window.gtag('event', eventName, {
    ...gtmEventParameters,
    platform,
    device_info: deviceInfo,
    current_path: window.location.pathname,
  });

  // Send to Singular (in mobile app) if requested
  if (sendAsConversionEventToMobileApp) {
    sendMobileAppConversionEvent(namespace, event, properties, user).catch(
      (error) => {
        rollbar.error('Failed to send mobile app conversion event', {
          error,
          namespace,
          event,
          properties,
          user,
        });
      },
    );
  }
};

const generateEventId = () => {
  return `${new Date().getTime()}-${Math.random()
    .toString(36)
    .substring(2, 15)}`;
};

const sendMobileAppConversionEvent = async (
  namespace: string,
  event: string,
  properties: Properties | null | undefined,
  user?: User,
) => {
  const [emailHash, phoneHash, firstNameHash, lastNameHash] = user
    ? await Promise.all([
        user.currentEmail
          ? hashString(user.currentEmail)
          : Promise.resolve(undefined),
        user.phoneNumber
          ? hashString(convertPhoneNumberToE164(user.phoneNumber))
          : Promise.resolve(undefined),
        user.firstName
          ? hashString(user.firstName)
          : Promise.resolve(undefined),
        user.lastName ? hashString(user.lastName) : Promise.resolve(undefined),
      ])
    : [];

  const singularAttributes = {
    ...properties,
    ehash: emailHash,
    phash: phoneHash,
    fnamehash: firstNameHash,
    lnamehash: lastNameHash,
  };

  sendMessageToMobileApp({
    eventType: 'kovo.webapp.track_conversion_event',
    body: {
      eventName: `${namespace}.${event}`,
      attributes: filterUndefinedValues(singularAttributes),
    },
  });
};

const convertPropertyNamesToSnakeCase = (properties: Properties) => {
  return Object.entries(properties).reduce<Record<string, any>>(
    (acc, [key, value]) => {
      acc[key.replace(/([A-Z])/g, '_$1').toLowerCase()] = value;
      return acc;
    },
    {},
  );
};

const convertDateToYYYYMMDD = (date: string): string | null => {
  if (!date || typeof date !== 'string') {
    return null;
  }

  // Parse the date using Luxon with explicit UTC timezone and MM-DD-YYYY format
  const parsed = DateTime.fromFormat(date, 'MM-dd-yyyy', { zone: 'UTC' });

  // Check if the parsing was successful
  if (!parsed.isValid) {
    return null;
  }

  // Format as YYYYMMDD in UTC
  return parsed.toUTC().toFormat('yyyyMMdd');
};

/**
 * Sets user data in Google Tag Manager (GTM) for analytics tracking
 *
 * This function takes user data and formats it for GTM, including:
 * - Personal info (name, email, phone, user ID)
 * - Address details (street, city, state, etc)
 * - Birthday
 *
 * The data is filtered to remove undefined values before being sent to GTM.
 * If an address is present, it ensures a country value (defaulting to 'US').
 *
 * @param user - Partial user object containing personal and address information
 */
export const setUserDataInGTM = (user: Partial<User>) => {
  const address = {
    first_name: user.firstName,
    last_name: user.lastName,
    country: user.country,
    street: user.address,
    city: user.city,
    region: user.state,
    postal_code: user.zipcode,
  };

  const phoneNumber = user.phoneNumber ?? user.phoneNumberUnverified;

  const userData = filterUndefinedValues({
    email_address: user.currentEmail,
    email: user.currentEmail,
    phone_number: phoneNumber
      ? convertPhoneNumberToE164(phoneNumber)
      : undefined,
    user_id: user.userId,
    birthday: user.birthday ? convertDateToYYYYMMDD(user.birthday) : undefined,
    address,
  });

  // Set the country to the user's country if the address is present
  if (userData.address) {
    userData.address.country = user.country ?? 'US';
  }

  window.gtag('set', 'user_data', userData);
};

/**
 * Identifies a customer across multiple analytics platforms
 *
 * This function:
 * 1. Configures Rollbar with the user ID for error tracking
 * 2. Sets the user ID in Google Tag Manager
 * 3. Identifies the user in PostHog analytics
 * 4. Sets user data in Google Tag Manager
 * 5. Sends an identify event to Google Analytics
 *
 * @param user - Partial user object containing user information, must include userId
 * @param eventId - Optional unique identifier for the event, defaults to generated ID
 */
export const identifyCustomer = (
  user: Partial<User>,
  eventId = generateEventId(),
) => {
  const userId = user.userId;

  if (rollbarEnabled && userId) {
    const rollbarUserId = userId.split(':')[1];
    rollbar.configure({
      payload: {
        person: {
          id: rollbarUserId,
        },
      },
    });
  }

  if (userId) {
    window.gtag('set', 'user_id', userId);
    window.posthog?.identify(userId, {
      kovoUserId: userId,
    });
  }

  setUserDataInGTM(user);

  window.gtag('event', 'identify', {
    event_id: eventId,
  });
};

export const useProductAnalytics = () => {
  const queryClient = useQueryClient();

  const track = useCallback(
    (params: TrackProductAnalyticsParams) => {
      // Dynamically fetch the latest user from the query client
      // Instead of relying on the `useCurrentUser` hook, which could be one/multiple renders behind.
      // This is particularly useful when the query cache is updated manually.
      const user = queryClient.getQueryData<User>(USE_CURRENT_USER_QUERY_KEY);
      if (user) {
        setUserDataInGTM(user);
      }
      trackProductAnalytics({ ...params, user });
    },
    [queryClient],
  );

  return { track };
};

// Export for testing
export const __test__ = {
  generateEventId,
  convertPropertyNamesToSnakeCase,
  sendMobileAppConversionEvent,
  convertDateToYYYYMMDD,
};
