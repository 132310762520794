import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { useEffect, useRef } from 'react';

import KovoDialog from 'components/KovoDialog';
import { KOVO_ORANGE, theme } from 'context/ThemeProvider';

export interface AddressData {
  address: string;
  address2?: string;
  city: string;
  state: string;
  zipcode: string;
}

type ComponentType = 'route' | 'subpremise' | 'postal_code' | string;

interface DialogProps extends Omit<ContentProps, 'onConfirm'> {
  open: boolean;
  onClose: () => void;
}

interface ContentProps extends AddressData {
  issues?: any[];
  missingComponents: string[];
  onConfirm: () => void;
}

export const InvalidAddressDialogContent: React.FC<ContentProps> = ({
  onConfirm,
  address,
  address2,
  city,
  state,
  zipcode,
  issues,
  missingComponents,
}) => {
  const { track } = useProductAnalytics();

  useEffect(() => {
    track({
      namespace: 'ui',
      event: 'address-input.invalid-address.shown',
    });
  }, []);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const formatComponentType = (componentType: ComponentType) => {
    if (componentType === 'subpremise') {
      return 'Apartment or unit number';
    }

    if (componentType === 'postal_code') {
      return 'ZIP code';
    }

    if (componentType === 'route') {
      return 'Address';
    }

    const withoutUnderscores = componentType.replace(/_/g, ' ');
    const capitalized = `${withoutUnderscores
      .charAt(0)
      .toUpperCase()}${withoutUnderscores.slice(1)}`;

    return capitalized;
  };
  const hasMissingComponents = Boolean(missingComponents?.length);
  const hasIssues = Boolean(issues?.length);

  return (
    <>
      <DialogTitle variant="h4">We couldn't verify your address</DialogTitle>

      <DialogContent>
        <Typography marginBottom={theme.spacing(2)}>
          Review the verification issues
        </Typography>

        <Typography marginBottom={theme.spacing(1)}>
          <strong>What you entered:</strong>
        </Typography>
        <Card variant="outlined">
          <CardContent>
            <Typography>{address}</Typography>
            {address2 && <Typography>{address2}</Typography>}
            <Typography>
              {city}, {state} {zipcode}
            </Typography>
          </CardContent>
        </Card>

        {hasMissingComponents && (
          <>
            <Typography sx={{ margin: theme.spacing(2, 0, 1) }}>
              <strong>Missing components:</strong>
            </Typography>
            {missingComponents?.map((componentType: ComponentType) => (
              <Alert
                severity="warning"
                sx={{
                  backgroundColor: 'transparent',
                  border: `2px solid ${KOVO_ORANGE}`,

                  ':not(:last-child)': { marginBottom: theme.spacing(1) },
                }}
                key={componentType}
              >
                <AlertTitle
                  sx={{
                    fontWeight: 600,
                    margin: 0,
                  }}
                >
                  {formatComponentType(componentType)} not found
                </AlertTitle>

                {componentType === 'route' && (
                  <Typography>Your address is missing a street</Typography>
                )}

                {componentType === 'subpremise' && (
                  <Typography>
                    Your address is missing an apartment or unit number
                  </Typography>
                )}

                {componentType === 'postal_code' && (
                  <Typography>Your address is missing a zip code</Typography>
                )}
              </Alert>
            ))}
          </>
        )}

        {hasIssues && (
          <>
            <Typography sx={{ margin: theme.spacing(2, 0, 1) }}>
              <strong>Verification issues:</strong>
            </Typography>
            {issues?.map((issue: any) => (
              <Alert
                severity="warning"
                sx={{
                  backgroundColor: 'transparent',
                  border: `2px solid ${KOVO_ORANGE}`,
                  borderRadius: '16px',

                  ':not(:last-child)': { marginBottom: theme.spacing(1) },
                }}
                key={issue.componentType}
              >
                <AlertTitle
                  sx={{
                    fontWeight: 600,
                    margin: 0,
                  }}
                >
                  {formatComponentType(issue.componentType)} not found
                </AlertTitle>

                {issue.componentName.text}
              </Alert>
            ))}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={onConfirm}
          ref={buttonRef}
          sx={{ flex: '1 1 50%' }}
        >
          Make Changes
        </Button>
      </DialogActions>
    </>
  );
};

const InvalidAddressDialog: React.FC<DialogProps> = ({
  open,
  address,
  address2,
  city,
  state,
  zipcode,
  issues,
  missingComponents,
  onClose,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <KovoDialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      sx={{ margin: theme.spacing(-2) }}
    >
      <InvalidAddressDialogContent
        address={address}
        address2={address2}
        city={city}
        state={state}
        zipcode={zipcode}
        issues={issues}
        missingComponents={missingComponents}
        onConfirm={handleClose}
      />
    </KovoDialog>
  );
};

export default InvalidAddressDialog;
