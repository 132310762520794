import { Box, Container } from '@mui/material';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';

import Footer from 'components/Footer';
import { theme } from 'context/ThemeProvider';
import usePrevious from 'hooks/usePrevious';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import OnboardingHeader from './OnboardingHeader';

interface Props {
  children?: React.ReactNode;
  maxWidth?: string;
  onboardingStep?: 1 | 2 | 3 | 4 | 5;
  centerLogo?: boolean;
  linkLogoToHomepage?: boolean;
  supportSubject?: string;
  openSupportAsLink?: boolean;
  showBackButton?: boolean;
  onBackButtonClick?: () => void;
  showFooter?: boolean;
}

const OnboardingPage: React.FC<Props> = ({
  children,
  maxWidth,
  onboardingStep,
  centerLogo,
  linkLogoToHomepage,
  supportSubject,
  openSupportAsLink,
  showBackButton,
  onBackButtonClick,
  showFooter = true,
}) => {
  const location = useLocation();
  const pathname = location.pathname;
  const previousPathname = usePrevious(pathname);
  const { track } = useProductAnalytics();

  useEffect(() => {
    if (previousPathname !== pathname) {
      track({
        namespace: 'ui',
        event: 'page.viewed',
      });
    }
  }, [previousPathname, pathname]);

  return (
    <Box
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingTop: theme.spacing(4),
          paddingBottom: theme.spacing(4),
        },
      }}
    >
      <Container
        sx={{
          padding: theme.spacing(1.5),
          paddingTop: 0,
          position: 'relative',
          backgroundColor: theme.palette.common.white,

          [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3),
            paddingTop: 0,
            maxWidth: maxWidth || '390px',
          },

          [theme.breakpoints.up('md')]: {
            maxWidth: maxWidth || '454px',
            minHeight: '0',
            overflow: 'hidden',
            borderRadius: theme.spacing(3),
            boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.8)',
          },

          [theme.breakpoints.down('md')]: {
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <OnboardingHeader
          onboardingStep={onboardingStep}
          centerLogo={centerLogo}
          linkLogoToHomepage={linkLogoToHomepage}
          showBackButton={showBackButton}
          onBackButtonClick={onBackButtonClick}
        />

        <Box sx={{ flexGrow: 1 }}>{children}</Box>

        {showFooter && (
          <Footer
            supportSubject={supportSubject}
            openSupportAsLink={openSupportAsLink}
          />
        )}
      </Container>
    </Box>
  );
};

export default OnboardingPage;
