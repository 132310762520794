import { Link as MuiLink, Typography } from '@mui/material';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { useState } from 'react';

import TextField from 'components/shared/TextField';

type VerificationCodeInputProps = {
  onResendCode: () => Promise<any>;
  onResendCodeSuccess?: () => void;
  onResendCodeError?: (error: any) => void;
  recentlySentLabel: string;
} & React.ComponentProps<typeof TextField>;

const VerificationCodeInput = ({
  onResendCode,
  onResendCodeSuccess,
  onResendCodeError,
  recentlySentLabel,
  ...other
}: VerificationCodeInputProps) => {
  const [hasRequestedCodeRecently, setHasRequestedCodeRecently] =
    useState<boolean>(false);
  const { track } = useProductAnalytics();

  const submitVerificationCodeAgain = () => {
    track({
      namespace: 'ui',
      event: 'resend-code.clicked',
    });

    setHasRequestedCodeRecently(true);
    onResendCode()
      .then(onResendCodeSuccess)
      .catch(onResendCodeError)
      .finally(() => {
        setTimeout(() => {
          setHasRequestedCodeRecently(false);
        }, 5000);
      });
  };

  const resendCodeHelperComponent = () => {
    if (hasRequestedCodeRecently) {
      return <Typography variant="footnote">{recentlySentLabel}</Typography>;
    }
    return (
      <Typography variant="footnote">
        Didn't get the code?{' '}
        <MuiLink
          component="button"
          type="button"
          sx={{
            fontSize: 'inherit',
            lineHeight: 'inherit',
            marginBottom: '1px',
          }}
          onClick={submitVerificationCodeAgain}
        >
          Resend
        </MuiLink>
        .
      </Typography>
    );
  };

  return <TextField helperText={resendCodeHelperComponent()} {...other} />;
};

export default VerificationCodeInput;
