import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Link as MuiLink,
  Skeleton,
  Typography,
} from '@mui/material';
import { useProductAnalytics } from 'libs/productAnalyticsTracking';
import { Link } from 'react-router-dom';

import { PaymentMethodLabel } from 'components/PaymentMethodLabel';
import { ROUTES } from 'components/Routes';
import { theme } from 'context/ThemeProvider';
import useGetAccounts from 'hooks/queries/useGetAccounts';

const ChargeAccountPaymentCard: React.FC = () => {
  const { data, isLoading } = useGetAccounts();
  const { track } = useProductAnalytics();

  if (isLoading) {
    return <Skeleton variant="rounded" height={211} />;
  }

  if (!data) {
    return null;
  }

  const { chargeAccount } = data;

  if (!chargeAccount || chargeAccount.status === 'closed') {
    return null;
  }

  const { primaryPaymentMethod } = chargeAccount;

  return (
    <Card elevation={0}>
      <CardContent>
        <Typography variant="h5">PAYMENT METHOD</Typography>

        <Divider sx={{ margin: theme.spacing(2, 0) }} />

        <Typography>
          <PaymentMethodLabel paymentMethod={primaryPaymentMethod} />
        </Typography>
      </CardContent>

      <CardActions sx={{ marginTop: theme.spacing(2) }}>
        <MuiLink
          component={Link}
          to={ROUTES.CREDIT_LINE_UPDATE_CARD}
          variant="footnote"
          onClick={() =>
            track({
              namespace: 'ui',
              event: 'payment.update-payment-method.clicked',
            })
          }
        >
          Update payment method
        </MuiLink>
      </CardActions>
    </Card>
  );
};

export default ChargeAccountPaymentCard;
