import { Auth } from 'aws-amplify';
import { KovoError } from 'libs/KovoError';

import {
  PENDING_EMAIL_ATTRIBUTE_KEY,
  PENDING_EMAIL_TIMESTAMP_ATTRIBUTE_KEY,
} from './types';

/**
 * Cancel an email update by just removing our custom pending email attributes
 */
export const cancelEmailAttribute = async () => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();

    const attributes = await Auth.userAttributes(currentUser);

    const hasPendingEmail = (attributes || []).some(
      (attr) => attr.Name === PENDING_EMAIL_ATTRIBUTE_KEY,
    );

    if (!hasPendingEmail) {
      return currentUser;
    }

    await Auth.deleteUserAttributes(currentUser, [
      PENDING_EMAIL_ATTRIBUTE_KEY,
      PENDING_EMAIL_TIMESTAMP_ATTRIBUTE_KEY,
    ]);

    const afterUpdate = await Auth.currentAuthenticatedUser();
    return afterUpdate;
  } catch (error) {
    throw new KovoError('Error cancelling email update', {
      error,
    });
  }
};
