import { getFeatureFlagValue } from 'helpers/featureFlags';
import { sendMessageToMobileApp } from 'helpers/mobile-app';
import useCreateReview from 'hooks/mutations/useCreateReview';
import useCurrentUser from 'hooks/queries/useCurrentUser';
import AppReviewDialogView from './AppReviewDialogView';

export interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const TRUST_PILOT_FALLBACK_URL =
  'https://www.trustpilot.com/evaluate/kovocredit.com';
const FEEDBACK_SURVEY_URL =
  'https://forms.reform.app/kovo/customer-feedback/xrp713';

const AppReviewDialog: React.FC<Props> = ({ isOpen, onClose }) => {
  const { data: userData } = useCurrentUser();
  const { mutate, isLoading } = useCreateReview();

  const handleClick = (rating: 0 | 1) => {
    mutate({
      ratingType: 'binary',
      rating,
      type: 'in_app',
      platform: 'web',
    });

    if (window.ReactNativeWebView) {
      sendMessageToMobileApp({
        eventType:
          rating === 1
            ? 'kovo.webapp.click.rate_us'
            : 'kovo.webapp.click.feedback_survey',
      });
    } else {
      if (rating === 1) {
        const reviewDestination =
          getFeatureFlagValue<string>('review_destination');
        let reviewUrl = userData?.trustPilotUrl || TRUST_PILOT_FALLBACK_URL;
        if (reviewDestination) {
          reviewUrl = reviewDestination;
        }
        window?.open(reviewUrl, '_blank')?.focus();
      } else {
        window?.open(FEEDBACK_SURVEY_URL, '_blank')?.focus();
      }
    }
  };

  return (
    <AppReviewDialogView
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      handleClick={handleClick}
    />
  );
};

export default AppReviewDialog;
