import { AlertColor } from '@mui/material';
import { Meta, StoryFn } from '@storybook/react';
import { KovoAlert } from './KovoAlert'; // Import the custom alert component

export default {
  title: 'Components/Kovo Design System/Alert',
  component: KovoAlert,
  argTypes: {
    severity: {
      control: {
        type: 'select',
        options: ['success', 'error', 'warning', 'info'],
      },
    },
    message: {
      control: 'text',
    },
  },
  design: {
    type: 'figma',
    url: 'https://www.figma.com/design/W9KjUJbereIhjwoF2rfVxD/Design-System?node-id=4591-5311&t=IZZhJAybNkSseUvG-4',
  },
} as Meta;

const Template: StoryFn<{ severity: AlertColor; message: string }> = (args) => (
  <KovoAlert {...args} />
);

export const SuccessAlert = Template.bind({});
SuccessAlert.args = {
  severity: 'success',
  message: 'Confirmation alert',
};

export const ErrorAlert = Template.bind({});
ErrorAlert.args = {
  severity: 'error',
  message: 'Error alert',
};

export const WarningAlert = Template.bind({});
WarningAlert.args = {
  severity: 'warning',
  message: 'Warning Information alert',
};

export const InfoAlert = Template.bind({});
InfoAlert.args = {
  severity: 'info',
  message: 'Neutral Information alert',
};
