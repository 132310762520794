import { InfoAlert } from 'components/KovoAlert/KovoAlert.stories';
import Page from 'components/Page';
import { isFeatureFlagEnabled } from 'helpers/featureFlags';
import UnrecognizedStatusPage from './UnrecognizedStatusPage';

const ServerErrorPage = () => {
  const isMaintenance = isFeatureFlagEnabled('MAINTENANCE_MODE_ENABLED');

  if (isMaintenance) {
    return (
      <Page>
        <InfoAlert
          severity="info"
          message="Our system is currently undergoing scheduled maintenance to improve
          your experience. Please try again in 15 minutes. We appreciate your
          patience!"
        />
      </Page>
    );
  }

  return <UnrecognizedStatusPage />;
};

export default ServerErrorPage;
