import { KovoAppCustomInfo, OutboundMobileAppMessage } from 'types';

export const APP_STORE_URL =
  'https://apps.apple.com/us/app/kovo-credit-builder-rewards/id6480379937';
export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.kovo.mobileapp';

export function getPlatformUserAgent(): 'android' | 'ios' | 'unknown' {
  var userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'ios';
  }

  return 'unknown';
}

export const isMobileWebview = Boolean(window && window.ReactNativeWebView);

export const getMobileAppDeviceInfo = (): KovoAppCustomInfo | null => {
  return window.KovoAppCustomInfo ?? null;
};

export const sendMessageToMobileApp = (message: OutboundMobileAppMessage) => {
  if (isMobileWebview) {
    window?.ReactNativeWebView?.postMessage(JSON.stringify(message));
  }
};
