const filterUndefinedValues = (
  obj: Record<string, any>,
): Record<string, any> => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    // Handle arrays
    if (Array.isArray(value)) {
      const filteredArray = value.map((item) => {
        if (item && typeof item === 'object') {
          return filterUndefinedValues(item);
        }
        return item;
      });

      // Only include non-empty arrays or arrays with content
      if (filteredArray.length > 0) {
        acc[key] = filteredArray;
      }
      return acc;
    }

    // Handle nested objects
    if (value && typeof value === 'object') {
      const filtered = filterUndefinedValues(value);
      // Only include the object if it has properties after filtering
      if (Object.keys(filtered).length > 0) {
        acc[key] = filtered;
      }
      return acc;
    }

    // Skip undefined and null values
    if (value === undefined || value === null) {
      return acc;
    }

    acc[key] = value;
    return acc;
  }, {} as Record<string, any>);
};

export default filterUndefinedValues;
